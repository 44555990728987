/// <reference types="vite-plugin-svgr/client" />
import * as React from 'react'
import { FieldPath, FieldValues } from 'react-hook-form'
import { CheckboxButtonGroup } from 'react-hook-form-mui'
import { Accordion, AccordionDetails, AccordionSummary } from '@mui/material'
import SvgExpandDownIcon from '@assets/icons/expandDownIcon.svg?react'
import { ControlledFormProps } from '@controls/types'
import { MenuItemEntry } from '@controls/utils'
import filterStyles from '../DesktopFilter.module.css'
import styles from './MultiValueFilter.module.css'

export type MultiValueFilterProps<TForm extends FieldValues> = ControlledFormProps<TForm> & {
  name: FieldPath<TForm>
  label: string
  options?: ReadonlyArray<MenuItemEntry>
}

export const MultiValueFilter = <TForm extends FieldValues>({
  control,
  name,
  options,
  label,
  disabledControls
}: MultiValueFilterProps<TForm>): React.ReactElement => {
  // TODO: Rework it with CSS and remove Typography from here
  return (
    <>
      <Accordion
        elevation={0}
        square={true}
        disableGutters={true}
        className={styles.multiValueContainer}
        // https://stackoverflow.com/questions/63488140/how-can-i-remove-line-above-the-accordion-of-material-ui
      >
        <AccordionSummary className={styles.multiValueCheckboxGroupLabel} expandIcon={<SvgExpandDownIcon />}>
          {label}
        </AccordionSummary>
        <AccordionDetails className={styles.multiValueCheckboxGroupContainer}>
          <CheckboxButtonGroup
            control={control}
            disabled={disabledControls}
            name={name}
            options={options?.map((v) => ({ id: v.value, label: v.label })) ?? []}
          />
        </AccordionDetails>
      </Accordion>
      <div className={filterStyles.containerWithUnderline} />
    </>
  )
}
