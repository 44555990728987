import * as React from 'react'
import { useTranslation } from 'react-i18next'

import { useMediaQuery } from '@mui/material'
import { useTheme } from '@hooks/useTheme'
import { useLanguage } from '@hooks/useLanguage'
import { useZodForm } from '@controls/Form'
import { Page, PageContainerWithHead } from '@layout/Page'
import { FlexContainer } from '@layout/FlexContainer'
import { CatalogFiltrationFormSchema } from '@forms/Catalog/types'
import { initialCatalogFiltrationForm } from '@forms/Catalog'
import { CatalogSort } from './CatalogSort'
import { FoundVehicles } from './FoundVehicles'
import { MobileFilter } from './MobileFilter'
import { DesktopFilter } from './DesktopFilter'
import pageStyles from '@assets/css/page.module.css'

export const CatalogPage: React.FC = () => {
  const { t } = useTranslation()
  const { routes } = useLanguage()
  const pageId = React.useId()
  const { currentTheme } = useTheme()
  const isDownMd = useMediaQuery(currentTheme.breakpoints.down('md'))

  const breadCrumbs = React.useMemo(
    () => [{ to: routes.Index, title: t('pages.main.title') }, { title: t('pages.catalog.title') }],
    [t, routes]
  )

  const form = useZodForm({
    schema: CatalogFiltrationFormSchema,
    defaultValues: initialCatalogFiltrationForm
  })

  return (
    <Page id={pageId}>
      {isDownMd && <div className={pageStyles.filterGap}></div>}
      <PageContainerWithHead title={t('pages.catalog.title')} breadCrumbs={breadCrumbs}>
        {!isDownMd && <CatalogSort />}
        <FlexContainer justifyContentSpaceBetween responsiveGap>
          {!isDownMd && <DesktopFilter form={form} pageId={pageId} />}
          <FoundVehicles />
        </FlexContainer>
      </PageContainerWithHead>
      {isDownMd && <MobileFilter form={form} pageId={pageId} />}
    </Page>
  )
}
