import * as React from 'react'
import { InfiniteData, useInfiniteQuery, UseInfiniteQueryResult } from '@tanstack/react-query'
import { OptionalType } from '@digital-magic/ts-common-utils'
import { getVehicleList, VehicleListResponse, VehiclesSearchQueryParams } from '@api/endpoints/vehicles'
import { useDefaultQueryErrorHandlerPublic } from '@hooks/ReactQuery'
import { queryKeyGetCars } from './queryKeys'

export const useQueryGetVehicleList = (
  filters: OptionalType<VehiclesSearchQueryParams>
): UseInfiniteQueryResult<InfiniteData<VehicleListResponse>> => {
  const errorHandler = useDefaultQueryErrorHandlerPublic()

  const query = useInfiniteQuery({
    queryKey: queryKeyGetCars(filters),
    queryFn: ({ pageParam }) => getVehicleList(pageParam, filters),
    initialPageParam: 0,
    getNextPageParam: (result) => {
      return result.totalPages > result.currentPage ? result.currentPage + 1 : undefined
    }
  })

  React.useEffect(() => {
    if (query.error) {
      errorHandler(query.error)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [query.error])

  return query
}
