import * as React from 'react'
import { BodyTypeEnumSchema } from '@api/endpoints/vehicles'
import { useEnumTranslation } from '@hooks/Translation/EnumTranslation'
import { useQueryGetVehicleActiveBodyTypes } from '@hooks/ReactQuery/vehicles'
import { buildEnumOptions, MenuItemEntry } from '@controls/utils'

export const useVehicleActiveBodyTypeMenuOptions = (): ReadonlyArray<MenuItemEntry> => {
  const getActiveBodyTypes = useQueryGetVehicleActiveBodyTypes()
  const { bodyTypeTranslation } = useEnumTranslation()

  return React.useMemo(
    () =>
      buildEnumOptions(BodyTypeEnumSchema, bodyTypeTranslation).filter(
        (v) => getActiveBodyTypes.data?.some((bodyType) => bodyType === v.value)
      ),
    [bodyTypeTranslation, getActiveBodyTypes.data]
  )
}
