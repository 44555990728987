import * as React from 'react'
import { UseFormReturn } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import clsx from 'clsx'
import { VehicleMake } from '@api/endpoints'
import clearFilter from '@assets/icons/clearFilter.svg'
import { Button, CircularProgress } from '@mui/material'
import formStyles from '@assets/css/forms.module.css'
import { AllValueSchema, ClassNameProps } from '@controls/types'
import {
  useTransmissionTypeOptions,
  useVehicleMakeMenuOptions,
  useVehicleModelMenuOptionsByMake,
  useVehicleActiveBodyTypeMenuOptions,
  useWheelDriveTypeOptions
} from '@controls/Menu'
import { FormTextField } from '@controls/Form'
import { RouterButton } from '@controls/buttons'
import { renderMenuItems } from '@controls/utils'
import { useVehicleGetActiveFuelTypeMenuOptions } from '@controls/Menu/MenuItemEntryHooks/useVehicleGetActiveFuelTypeMenuOptions'
import { FlexContainer } from '@layout/FlexContainer'
import { CatalogFiltrationForm } from '@forms/Catalog/types'
import { initialCatalogFiltrationForm, useCatalogParamsStore } from '@forms/Catalog'
import { RangeFilter } from './RangeFilter'
import { MultiValueFilter } from './MultiValueFilter'
import styles from './DesktopFilter.module.css'

type Props = ClassNameProps &
  Readonly<{
    form: UseFormReturn<CatalogFiltrationForm>
    pageId?: string | undefined
    closeMobileFilter?: () => void
  }>

export const DesktopFilter: React.FC<Props> = ({ form, pageId, className, closeMobileFilter }) => {
  const { t } = useTranslation()
  const [extendedSearchOptionsShown, setExtendedSearchOptionsShown] = React.useState(false)

  const [make, setMake] = React.useState<VehicleMake | undefined>(undefined)

  const catalogFiltrationForm = useCatalogParamsStore((s) => s.catalogFiltrationForm)
  const setCatalogFiltrationForm = useCatalogParamsStore((s) => s.setCatalogFiltrationForm)

  const menuOptionSelectMake = t('pages.catalog.selectMake')
  const menuOptionSelectModel = t('pages.catalog.selectModel')
  const vehicleBodyTypeOptions = useVehicleActiveBodyTypeMenuOptions()
  const fuelTypeOptions = useVehicleGetActiveFuelTypeMenuOptions()
  const vehicleMakeOptions = useVehicleMakeMenuOptions(menuOptionSelectMake)
  const vehicleModelOptions = useVehicleModelMenuOptionsByMake(make, menuOptionSelectModel)
  const transmissionTypeOptions = useTransmissionTypeOptions(/*menuOptionLabelAll*/)
  const wheelDriveTypeOptions = useWheelDriveTypeOptions(/*menuOptionLabelAll*/)

  const handleSubmit = (data: CatalogFiltrationForm): void => {
    setCatalogFiltrationForm(data)
    if (pageId) {
      document.getElementById(pageId)?.scrollTo(0, 60)
    }
    if (closeMobileFilter) {
      closeMobileFilter()
    }
  }

  const resetHandler = (): void => {
    form.reset()
    setCatalogFiltrationForm(initialCatalogFiltrationForm)
  }

  React.useEffect(() => {
    form.reset(catalogFiltrationForm)
    setMake(catalogFiltrationForm.make)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [catalogFiltrationForm])

  const onMakeChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { model, ...values } = form.getValues()
    // TODO: Resolve why if we don't specify module it won't automatically reset to form default value
    form.reset({ model: AllValueSchema.value, ...values })
    setMake(e.target.value)
  }

  return (
    <div className={clsx(styles.filterOptionsContainer, className)}>
      <form onSubmit={form.handleSubmit(handleSubmit)}>
        <FlexContainer vertical>
          {vehicleMakeOptions.data && vehicleMakeOptions.data.some((v) => v.value === form.watch('make')) ? (
            <FormTextField
              control={form.control}
              name="make"
              select
              //label={t('pages.catalog.filterCriteria.make')}
              className={formStyles.inputWhiteBg}
              onChange={onMakeChange}
            >
              {renderMenuItems(vehicleMakeOptions.data)}
            </FormTextField>
          ) : (
            <CircularProgress />
          )}
          {vehicleModelOptions.data && vehicleModelOptions.data.some((v) => v.value === form.watch('model')) ? (
            <FormTextField
              control={form.control}
              name="model"
              select
              disabled={vehicleModelOptions.data.length <= 1}
              //label={t('pages.catalog.filterCriteria.model')}
              className={formStyles.inputWhiteBg}
            >
              {renderMenuItems(vehicleModelOptions.data)}
            </FormTextField>
          ) : (
            <CircularProgress />
          )}
          {extendedSearchOptionsShown && (
            <>
              {vehicleBodyTypeOptions ? (
                <MultiValueFilter
                  control={form.control}
                  name="variants"
                  label={t('pages.catalog.filterCriteria.bodyType')}
                  options={vehicleBodyTypeOptions}
                />
              ) : (
                <CircularProgress />
              )}
              <MultiValueFilter
                control={form.control}
                name="fuels"
                label={t('pages.catalog.filterCriteria.fuelType')}
                options={fuelTypeOptions}
              />
              {!transmissionTypeOptions.isFetching ? (
                <MultiValueFilter
                  control={form.control}
                  name="transmissions"
                  label={t('pages.catalog.filterCriteria.gearboxType')}
                  options={transmissionTypeOptions.data}
                />
              ) : (
                <CircularProgress />
              )}
              {!wheelDriveTypeOptions.isFetching ? (
                <MultiValueFilter
                  control={form.control}
                  name="wheelDrives"
                  label={t('pages.catalog.filterCriteria.transmissionType')}
                  options={wheelDriveTypeOptions.data}
                />
              ) : (
                <CircularProgress />
              )}
              <RangeFilter
                control={form.control}
                parentName="price"
                nameFrom="price.from"
                nameTo="price.to"
                className={formStyles.inputWhiteBg}
                label={t('pages.catalog.filterCriteria.priceRange.title')}
                fromLabel={t('pages.catalog.filterCriteria.priceRange.from')}
                toLabel={t('pages.catalog.filterCriteria.priceRange.to')}
              />
              <RangeFilter
                control={form.control}
                parentName="powerInKw"
                nameFrom="powerInKw.from"
                nameTo="powerInKw.to"
                className={formStyles.inputWhiteBg}
                label={t('pages.catalog.filterCriteria.powerRange.title')}
                fromLabel={t('pages.catalog.filterCriteria.powerRange.from')}
                toLabel={t('pages.catalog.filterCriteria.powerRange.to')}
              />
              <RangeFilter
                control={form.control}
                parentName="year"
                nameFrom="year.from"
                nameTo="year.to"
                className={formStyles.inputWhiteBg}
                label={t('pages.catalog.filterCriteria.productionYearRange.title')}
                fromLabel={t('pages.catalog.filterCriteria.productionYearRange.from')}
                toLabel={t('pages.catalog.filterCriteria.productionYearRange.to')}
              />
              <RangeFilter
                control={form.control}
                parentName="mileage"
                nameFrom="mileage.from"
                nameTo="mileage.to"
                className={formStyles.inputWhiteBg}
                label={t('pages.catalog.filterCriteria.odometerReadingRange.title')}
                fromLabel={t('pages.catalog.filterCriteria.odometerReadingRange.from')}
                toLabel={t('pages.catalog.filterCriteria.odometerReadingRange.to')}
              />

              {/*<RangeFilter*/}
              {/*  control={form.control}*/}
              {/*  parentName="engineDisplacementRange"*/}
              {/*  nameFrom="engineDisplacementRange.from"*/}
              {/*  nameTo="engineDisplacementRange.to"*/}
              {/*  className={formStyles.inputWhiteBg}*/}
              {/*  label={t('pages.catalog.filterCriteria.engineDisplacementRange.title')}*/}
              {/*  fromLabel={t('pages.catalog.filterCriteria.engineDisplacementRange.from')}*/}
              {/*  toLabel={t('pages.catalog.filterCriteria.engineDisplacementRange.to')}*/}
              {/*/>*/}
            </>
          )}
          <div className={styles.filterActions}>
          {extendedSearchOptionsShown && (
            <RouterButton
              variant="text"
              startIcon={<img src={clearFilter} alt={t('pages.catalog.resetSearchOptions')} loading="lazy" />}
              onClick={resetHandler}
              className={styles.resetSearchOptionsButton}
            >
              <span  className={styles.resetSearchOptionsButtonText} >{t('pages.catalog.resetSearchOptions')}</span>
            </RouterButton>
          )}
          <Button variant="contained" className={styles.btnSubmit} fullWidth type="submit">
            {t('global.buttons.confirm')}
          </Button>
          </div>
          {!extendedSearchOptionsShown && (
            <Button
              variant="text"
              onClick={() => {
                setExtendedSearchOptionsShown((shown) => !shown)
              }}
              className={styles.underlinedLink}
              fullWidth
            >
              {t('pages.catalog.extendedSearchOptions.show')}
            </Button>
          )}
        </FlexContainer>
      </form>
    </div>
  )
}
