/// <reference types="vite-plugin-svgr/client" />
import * as React from 'react'
import { FieldPath, FieldValues, useController } from 'react-hook-form'
import { hasValue } from '@digital-magic/ts-common-utils'
import { Accordion, AccordionDetails, AccordionSummary, Stack } from '@mui/material'
import { AccordionProps } from '@mui/material/Accordion/Accordion'
import SvgExpandDownIcon from '@assets/icons/expandDownIcon.svg?react'
import { FormTextField, FormTextFieldProps } from '@controls/Form'
import filterStyles from '../DesktopFilter.module.css'
import styles from './RangeFilter.module.css'

export type RangeFilterProps<TForm extends FieldValues> = {
  parentName: FieldPath<TForm>
  nameFrom: FieldPath<TForm>
  nameTo: FieldPath<TForm>
  fromLabel: string
  toLabel: string
} & Pick<FormTextFieldProps<TForm>, 'control' | 'label' | 'variant' | 'className'>

export const RangeFilter = <TForm extends FieldValues>({
  control,
  parentName,
  nameFrom,
  nameTo,
  label,
  fromLabel,
  toLabel,
  ...inputProps
}: RangeFilterProps<TForm>): React.ReactElement => {
  const { field: parentField } = useController({ name: parentName, control })

  const resetToDefault = React.useCallback(() => {
    if (hasValue(parentField.value)) {
      parentField.onChange(undefined)
    }
  }, [parentField])

  const handleOnChange: AccordionProps['onChange'] = (_, expanded) => {
    if (!expanded) {
      resetToDefault()
    }
  }
  return (
    <>
      <Accordion
        onChange={handleOnChange}
        elevation={0}
        square={true}
        disableGutters={true}
        className={styles.rangeContainer}
        // https://stackoverflow.com/questions/63488140/how-can-i-remove-line-above-the-accordion-of-material-ui
        sx={{
          '&:before': {
            display: 'none'
          }
        }}
      >
        <AccordionSummary expandIcon={<SvgExpandDownIcon />}>{label}</AccordionSummary>
        <AccordionDetails>
          <Stack flexDirection="row" justifyContent="space-between">
            <FormTextField
              control={control}
              name={nameFrom}
              type="number"
              className={styles.rangeEntryField}
              placeholder={fromLabel}
              {...inputProps}
              InputProps={{
                inputProps: { min: 0 }
              }}
            />
            <FormTextField
              control={control}
              name={nameTo}
              type="number"
              className={styles.rangeEntryField}
              placeholder={toLabel}
              {...inputProps}
              InputProps={{
                inputProps: { min: 0 }
              }}
            />
          </Stack>
        </AccordionDetails>
      </Accordion>
      <div className={filterStyles.containerWithUnderline} />
    </>
  )
}
