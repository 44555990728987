import * as React from 'react'
import { useQuery, UseQueryResult } from '@tanstack/react-query'
import { BodyTypeEnum, getActiveBodyTypes } from '@api/endpoints/vehicles'
import { useDefaultQueryErrorHandlerPublic } from '@hooks/ReactQuery'
import { queryKeyGetActiveBodyTypes } from './queryKeys'

export const useQueryGetVehicleActiveBodyTypes = (): UseQueryResult<ReadonlyArray<BodyTypeEnum>> => {
  const errorHandler = useDefaultQueryErrorHandlerPublic()
  const query = useQuery({
    queryKey: queryKeyGetActiveBodyTypes,
    queryFn: getActiveBodyTypes
  })

  React.useEffect(() => {
    if (query.error) {
      errorHandler(query.error)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [query.error])

  return query
}
