import * as React from 'react'
import { useTranslation } from 'react-i18next'
import clsx from 'clsx'
import { useInView } from 'react-intersection-observer'
import { VehicleShort } from '@api/endpoints/vehicles/types'
import { CircularProgress } from '@mui/material'
import { useQueryGetVehicleList } from '@hooks/ReactQuery/vehicles/useQueryGetVehicleList'
import { ButtonAddToCompare, RouterButton } from '@controls/buttons'
import { FlexContainer } from '@layout/FlexContainer'
import { VehicleCard } from '@layout/VehicleCard'
import { toVehiclesSearchQueryParams, useCatalogParamsStore } from '@forms/Catalog'
import { urlBySelectionMode } from '@pages/utils'
import { useSearchParamsSelectionMode } from '@pages/useSearchParamsSelectionMode'
import { useLanguage } from '@hooks/useLanguage'
import styles from './FoundVehicles.module.css'

export const FoundVehicles: React.FC = () => {
  const { t } = useTranslation()
  const { language } = useLanguage()
  const { ref, inView } = useInView()
  const selectionMode = useSearchParamsSelectionMode()

  const catalogFiltrationForm = useCatalogParamsStore((s) => toVehiclesSearchQueryParams(s.catalogFiltrationForm))

  const sortBy = useCatalogParamsStore((state) => state.sortBy)
  const getVehicles = useQueryGetVehicleList({ sortBy, ...catalogFiltrationForm })

  React.useEffect(() => {
    if (inView && getVehicles.hasNextPage) {
      void getVehicles.fetchNextPage()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inView])

  const pages = getVehicles.data?.pages ?? []

  return pages.length === 0 || (pages.length === 1 && pages[0]?.content?.length === 0) ? (
    <FlexContainer wrap justifyContentCenter>
      <p>{t('pages.catalog.noCarsWereFound')}</p>
    </FlexContainer>
  ) : (
    <FlexContainer wrap responsiveGap className={clsx(styles.rightPaneContainer, 'cardsSmUnsetHeight')}>
      {pages.map((page, pageIndex) => (
        <React.Fragment key={pageIndex}>
          {page.content.map((descriptor: VehicleShort, i) => (
            <VehicleCard
              key={descriptor.id}
              cardRef={pageIndex === pages.length - 1 && i === page.content.length - 1 ? ref : undefined}
              vehicle={descriptor}
              selectionMode={selectionMode}
              className={styles.catalogCardContainer}
            >
              {selectionMode ? (
                <RouterButton
                  variant="contained"
                  fullWidth
                  className={styles.chooseBtn}
                  to={urlBySelectionMode(selectionMode, descriptor.id, language)}
                >
                  {t('components.cards.choose')}
                </RouterButton>
              ) : (
                <ButtonAddToCompare vehicleId={descriptor.id} className={styles.compareBtn} />
              )}
            </VehicleCard>
          ))}
        </React.Fragment>
      ))}
      {getVehicles.isFetchingNextPage && (
        <FlexContainer justifyContentCenter alignItemsCenter>
          <CircularProgress />
        </FlexContainer>
      )}
    </FlexContainer>
  )
}
