/// <reference types="vite-plugin-svgr/client" />
import * as React from 'react'
import { useTranslation } from 'react-i18next'
import { TextField } from '@mui/material'
import { VehicleSortByEnum, VehicleSortByEnumSchema } from '@api/endpoints/vehicles/types'
import SvgExpandDownIcon from '@assets/icons/expandDownIcon.svg?react'
import { useCatalogParamsStore } from '@forms/Catalog'
import styles from './CatalogSort.module.css'
import { useEnumTranslation } from '@hooks/Translation/EnumTranslation'
import { buildEnumOptions, MenuItemEntry, renderMenuItems } from '@controls/utils'

export const CatalogSort: React.FC = () => {
  const { t } = useTranslation()
  const { vehiclesSortByTranslation } = useEnumTranslation()

  const sortBy = useCatalogParamsStore((state) => state.sortBy)
  const setSortBy = useCatalogParamsStore((state) => state.setSortBy)

  const sortByOptions: ReadonlyArray<MenuItemEntry> = React.useMemo(
    () =>
      buildEnumOptions(VehicleSortByEnumSchema, vehiclesSortByTranslation).filter(
        (v) => v.value !== VehicleSortByEnumSchema.enum.MILEAGE
      ),
    [vehiclesSortByTranslation]
  )

  const onSortByChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>): void => {
    setSortBy(e.target.value as VehicleSortByEnum)
  }

  return (
    <div className={styles.catalogTypeSortContainer}>
      <TextField
        select
        className={styles.sortSelector}
        value={sortBy}
        variant="standard"
        label={t('pages.catalog.sortImperative')}
        SelectProps={{ IconComponent: SvgExpandDownIcon }}
        onChange={onSortByChange}
      >
        {renderMenuItems(sortByOptions)}
      </TextField>
    </div>
  )
}
