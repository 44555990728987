import * as React from 'react'
import { UseFormReturn } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { DrawerProps, SwipeableDrawer } from '@mui/material'
import { ButtonClose, RouterButton } from '@controls/buttons'
import { FlexContainer } from '@layout/FlexContainer'
import { CatalogFiltrationForm } from '@forms/Catalog/types'
import { DesktopFilter } from '../DesktopFilter'
import { CatalogSort } from '../CatalogSort'
import styles from './MobileFilter.module.css'

type Props = Omit<DrawerProps, 'open' | 'onClose'> &
  Readonly<{
    form: UseFormReturn<CatalogFiltrationForm>
    pageId?: string | undefined
  }>

const iOS = typeof navigator !== 'undefined' && /iPad|iPhone|iPod/.test(navigator.userAgent)

export const MobileFilter: React.FC<Props> = ({ form, pageId, ...drawerProps }) => {
  const { t } = useTranslation()
  const [filterOpen, setFilterOpen] = React.useState(false)

  return (
    <>
      <SwipeableDrawer
        anchor="top"
        className={styles.mobileFilterDrawer}
        disableBackdropTransition={!iOS}
        disableDiscovery={iOS}
        open={filterOpen}
        onClose={() => setFilterOpen(false)}
        onOpen={() => setFilterOpen(true)}
        {...drawerProps}
      >
        <ButtonClose className={styles.closeBtn} onClick={() => setFilterOpen(false)} />
        <DesktopFilter
          form={form}
          pageId={pageId}
          className={styles.mobileFilterContainer}
          closeMobileFilter={() => setFilterOpen(false)}
        />
      </SwipeableDrawer>
      <FlexContainer fixedDirection justifyContentSpaceBetween className={styles.controlsContainer}>
        <RouterButton variant="text" color="blue" className={styles.filterBtn} onClick={() => setFilterOpen(true)}>
          {t('pages.catalog.filters')}
        </RouterButton>
        <CatalogSort />
      </FlexContainer>
    </>
  )
}
