import * as React from 'react'
import { FuelTypeEnumSchema } from '@api/endpoints/vehicles'
import { useEnumTranslation } from '@hooks/Translation/EnumTranslation'
import { buildEnumOptions, MenuItemEntry } from '@controls/utils'

export const useVehicleGetActiveFuelTypeMenuOptions = (): ReadonlyArray<MenuItemEntry> => {
  const { fuelTypeTranslation } = useEnumTranslation()

  return React.useMemo(() => buildEnumOptions(FuelTypeEnumSchema, fuelTypeTranslation), [fuelTypeTranslation])
}
